// 会议管理
import request from '@/utils/request';

export default {
    list(params) {
        return request.get('/credit/complaint', { params });
    },

    create(data) {
        return request.post('/credit/complaint/create', data);
    },
    update(data) {
        return request.post('/credit/complaint/update', data);
    },
    remove(data) {
        return request.post('/credit/complaint/remove', data);
    },
    process(data) {
        return request.post('/credit/complaint/process', data);
    },
    detail(id) {
        return request.get('/credit/complaint/detail?id='+id);
    },
    


};