<template>
  <div class="app-container">
    <div class="top" style="margin: 10px 0">
      <el-button
        type="success"
        icon="el-icon-plus"
        @click="showEdit(selecTionArr[0])"
        :disabled="selecTionArr.length !== 1"
        >处理投诉</el-button
      >
    </div>
    <el-table
      :data="listData"
      @selection-change="getSelection($event)"
      style="width: 100%; margin-bottom: 20px; text-align: center"
      border
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="merchantPersonnelName"
        label="商户名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="merchantStallNo"
        label="摊位编号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="content"
        label="投诉内容"
        align="center"
        width="500"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="投诉时间"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="processDate"
        label="处理时间"
        align="center"
      ></el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'">{{
            scope.row.status | StatusFilter
          }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column
        prop="deduction"
        label="扣分"
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      background
      v-bind="pageInfo"
      :layout="layout"
      @size-change="onSizeChange"
      @current-change="onPageChange"
    />
    <el-dialog
      :title="'投诉处理'"
      :visible.sync="dialogVisible"
      width="800px"
      center
    >
      <el-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :inline="true"
        label-width="120px"
      >
        <!-- <el-form-item label="商户姓名">
          <el-select v-model="form.merchantPersonnelId" @change="setIdCardVal">
            <el-option
              v-for="(item, index) in merchantPersonArr"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="商户名称" prop="merchantPersonnelName">
          <el-input v-model="form.merchantPersonnelName" disabled />
        </el-form-item>
        <el-form-item label="摊位编号" prop="merchantStallNo">
          <el-input v-model="form.merchantStallNo" disabled />
        </el-form-item>
        <el-form-item label="投诉时间" prop="createTime">
          <el-input v-model="form.createTime" disabled />
        </el-form-item>
        <el-form-item label="扣分" prop="deduction">
          <el-input v-model="form.deduction" />
        </el-form-item>
        <el-form-item label="当前状态">
          <el-radio-group v-model="form.status" class="radioWidth">
            <el-radio :label="0" border>未处理</el-radio>
            <el-radio :label="1" border>已处理</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="处理结果" prop="processResult" class="fullWidth">
          <el-input v-model="form.processResult" class="fullInput" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>

        <el-button type="primary" @click="handleEdit('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/credit";

export default {
  data() {
    return {
      rules: {
        deduction: [
          {
            type:"number",
            required: true,
            validator: (relu, value, callback) => {
              if (!value) {
                callback(new Error("请输入分数"));
              } else if (!/^\d+$/.test(value)) {
                callback(new Error("请填写阿拉伯数"));
              } else {
                callback();
              }
            },
            trigger: ["change"],
          },
        ],
        processResult: [
          { required: true, message: "请输入处理结果", trigger: "blur" },
        ],
      },
      listData: [],
      selecTionArr: [],
      handelType: "",
      dialogVisible: false,
      form: {},
      pageInfo: {
        currentPage: 1,
        pageCount: 1,
        pageSize: 15,
        total: 0,
      },
      layout: "sizes, prev, pager, next, jumper",
    };
  },
  filters: {
    StatusFilter(val) {
      const statusMap = { 0: "未处理", 1: "已处理" };
      return statusMap[val];
    },
  },
  created() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      await api.list().then((res) => {
        this.listData = res.data.data;
      });
    },
    getSelection(e) {
      this.selecTionArr = e;
    },
    async showEdit(obj) {
      await api.detail(obj.id).then((res) => {
        this.form = res.data;
        console.log(this.form);
        console.log(res.data);
      });

      this.dialogVisible = true;
    },
    async handleEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            id: this.form.id,
            deduction: this.form.deduction,
            processResult: this.form.processResult,
          };
          try {
            api.process(data);
            this.dialogVisible = false;
            this.getDataList();
            this.$message({ message: "处理成功！", type: "success" });
          } catch (error) {
            this.$message({ message: "系统异常", type: "error" });
          }
        }else{
          return false
        }
      });
    },
    async onSizeChange(e) {
      this.pageInfo.pageSize = e;
      this.getDataList();
    },
    async onPageChange(e) {
      this.pageInfo.currentPage = e;
      this.getDataList();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.info-form label {
  font-weight: normal;
}
.el-form-item__label {
  text-align: justify;
  height: 50px;
}
.searchInput {
  width: 400px;
}
.fullWidth {
  width: 100% !important;
}
.fullInput {
  width: 570px;
}
</style>
